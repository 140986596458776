.suspense {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  min-height: 100%;
}

.full-height {
  min-height: 100vh;
}
