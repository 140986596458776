/* ===== Sidebar ===== */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 10px 14px;
  background: var(--sidebar-color);
  transition: var(--tran-05);
  z-index: 100;
}

.sidebar-ar {
  left: auto;
}

.toggle-button {
  position: fixed;
  transform: none;
  color: var(--primary-color);
  border-radius: var(--border-radius);
  border: 1px solid var(--primary-color);
  font-size: 1.3rem;
  padding: 8px 5px;
}

.sidebar.close {
  width: 88px;
}

/* styles to apply on mobile devices */

@media (max-width: 45em) {
  .sidebar {
    background-color: var(--sidebar-color) !important;
    width: 100%;
    transition: var(--tran-05);
  }

  .sidebar.close {
    width: 0 !important;
    padding: 0 !important;
    opacity: 0;
    visibility: hidden;
  }

  footer {
    left: 0 !important;
    width: 100% !important;
  }

  .sidebar .toggle {
    display: none !important;
  }

  .home {
    left: 0 !important;
    width: 100% !important;
    padding: 0.6rem !important;
  }

  /* this overwrites the .home-ar on big screens, "auto" unsets the right, left etc properties */
  .home-ar {
    left: auto !important;
    right: auto !important;
  }

  .navbar {
    grid-template-columns: 0.8fr 1fr 1fr !important;
    padding: 0.5rem 0 !important;
  }
  .navbar img {
    width: 60px !important;
    height: 60px !important;
  }

  .navbar button.hamburger {
    display: flex !important;
    justify-content: center;
  }

  .navbar .logo {
    display: flex !important;
    justify-content: center;
    visibility: visible !important;
  }

  .sidebar header .logo button.mobile-toggle {
    display: block !important;
  }

  .navbar .welcome {
    display: none !important;
  }
}

.sidebar li {
  height: 50px;
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.image {
  text-align: center;
  min-width: 60px;
  border-radius: var(--border-radius);
}
.sidebar .icon {
  min-width: 60px;
  border-radius: var(--border-radius);
}

.sidebar .icon {
  min-width: 60px;
  border-radius: var(--border-radius);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.sidebar .text,
.sidebar .icon {
  color: var(--text-color);
  transition: var(--tran-03);
}

.sidebar .text {
  font-size: 17px;
  font-weight: 500;
  white-space: nowrap;
  opacity: 1;
}
.sidebar.close .text {
  opacity: 0;
}
/* =========================== */

.sidebar header {
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
  opacity: 1;
}

.logo-text {
  display: flex;
  flex-direction: column;
  float: right;
}

.logo .name {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 600;
}

.logo .profession {
  font-size: 16px;
  margin-top: -2px;
  display: block;
}

nav img,
.navbar img {
  width: 40px;
  border-radius: 15%;
}

.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background-color: var(--primary-color);
  border: none;
  color: var(--sidebar-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
  transition: var(--tran-05);
}

.sidebar header .toggle-ar {
  right: auto;
  left: -25px;
}

.sidebar header .toggle i {
  font-size: 0.8rem;
  display: inline-block;
  height: 50%;
}

.sidebar.close .toggle {
  transform: translateY(-50%) rotate(0deg);
}

.sidebar header .logo button.mobile-toggle {
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  color: var(--primary-color);
  display: grid;
  place-items: center;
  margin-left: auto;
  display: none;
}

.mobile-toggle-ar {
  margin-right: auto;
  margin-left: 0 !important;
}

.sidebar .menu {
  margin-top: 40px;
}

.sidebar li a {
  list-style: none;
  height: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: var(--border-radius);
  text-decoration: none;
  transition: var(--tran-03);
}

.logoutbtn {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: var(--border-radius);
  background-color: transparent;
  display: flex;
  align-items: center;
  transition: var(--tran-04);
  color: var(--text-color);
}

.logoutbtn img {
  max-width: 35px;
  margin-left: auto;
  margin-right: 10px;
  background-color: var(--primary-color-light);
}

.logoutbtn:hover {
  background-color: var(--primary-color);
}

.logoutbtn:hover .icon,
.logoutbtn:hover .text {
  color: var(--sidebar-color);
}

.sidebar li a:hover {
  background-color: var(--primary-color);
}
.sidebar li a:hover .icon,
.sidebar li a:hover .text {
  color: var(--sidebar-color);
}

.sidebar .menu-bar {
  height: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}

.menu-bar::-webkit-scrollbar {
  display: none;
}

.sidebar .menu-bar .mode {
  border-radius: var(--border-radius);
  background-color: var(--primary-color-light);
  position: relative;
  transition: var(--tran-05);
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
}

.mode .theme-toggle {
  justify-self: end;
  margin-right: 1rem;
}

.mode .theme-toggle i {
  font-size: 2rem;
  display: grid;
  place-items: center;
}

.menu-links {
  padding: 0;
}

.home {
  position: absolute;
  left: 250px;
  width: calc(100% - 250px);
  background-color: var(--body-color);
  transition: var(--tran-05);
  padding: 12px 60px;
  font-weight: 500;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.home-ar {
  left: auto;
  right: 250px;
}

@media (max-width: 57.5em) {
  .home {
    padding: 5px 20px;
  }
}

@media (min-width: 81em) {
  .home {
    max-width: 80rem;
  }
}

.sidebar.close ~ main .home {
  left: 78px;
  width: calc(100% - 78px);
}

.sidebar.close ~ main .home-ar {
  left: auto;
  right: 78px;
  width: calc(100% - 78px);
}

.home footer {
  text-align: center;
  color: var(--text-color);
  transition: var(--tran-05);
  padding: 12px 60px;
  font-size: 0.8rem;
  margin-top: auto;
}

.active-nav .icon,
.active-nav .text {
  color: var(--primary-color);
}

.navbar {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1fr 0.2fr;
  align-items: center;
  gap: 1rem;
}

.navbar .logo {
  visibility: hidden;
  display: none;
}

.navbar button.hamburger {
  background-color: transparent;
  border: none;
  justify-self: start;
  display: none;
}

.navbar i {
  font-size: 2rem;
  color: var(--primary-color);
}

.navbar .welcome {
  font-size: 2rem;
}

.navbar .cart {
  background-color: var(--primary-color-light);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-self: end;
  gap: 0.2rem;
  max-width: fit-content;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.navbar .cart span {
  margin-left: 5px;
  display: flex;
  justify-content: center;
  min-width: 20%;
}

.langs {
  width: 100%;
  border-radius: var(--border-radius);
}

.langs button {
  color: var(--text-color);
  border: none;
  background-color: transparent;
}

.langs div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.active-lang {
  color: var(--primary-color) !important;
}
