.loading p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 0;
}

.loading span.message {
  color: var(--primary-color);
}

.loading-spinner {
  display: flex;
  align-items: center;
}

.loading-spinner::after {
  content: "";
  width: 1.3rem;
  height: 1.3rem;
  border: 0.2rem solid var(--primary-color-light);
  border-top-color: var(--primary-color);
  border-radius: 50%;
  animation: load 1s linear infinite;
}

@keyframes load {
  to {
    rotate: 1turn;
  }
}
