/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: hsl(200, 18%, 90%);
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* Google Font Import - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Poppins", sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Ubuntu";
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

:root {
  /* ===== Colors ===== */
  --body-color: #e4e9f7;
  --sidebar-color: #fff;
  --primary-color: #695cfe;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #707070;
  --text-color-disabled: #cccccc;
  --primary-color-disabled: #a69efd;
  --green-accent-color: #6dbba0;
  --green-color: #26924f;
  /* --red-accent-color: #bc7373; */
  --red-accent-color: #c36161;
  --yellow-accent-color: #ffc107;
  /* ====== Transition ====== */
  --tran-03: all 0.2s ease-in-out;
  --tran-03: all 0.3s ease-in-out;
  --tran-04: all 0.3s ease-in-out;
  --tran-05: all 0.3s ease-in-out;
  --tran-06: all 0.1s ease-in-out;
  /* ====== UI ====== */
  --border-radius: 6px;
}

body {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background-color: var(--body-color);
  transition: var(--tran-05);
}

::selection {
  background-color: var(--primary-color);
  color: #fff;
}

body.dark {
  --body-color: #18191a;
  --sidebar-color: #242526;
  /* --primary-color: #3a3b3c; */
  --primary-color: #46709b;
  --primary-color-light: #3a3b3c;
  --primary-color-disabled: var(--primary-color-light);
  /* --red-accent-color: #ffa9a9; */
  --toggle-color: #fff;
  --text-color: #ccc;
}

body.dark input[type="text"].form-control,
body.dark input[type="email"].form-control,
body.dark input[type="password"].form-control,
body.dark input[type="number"].form-control,
body.dark textarea.form-control,
body.dark select.form-select {
  box-shadow: none;
  background-color: var(--primary-color-light);
  border: none;
  color: var(--text-color);
}

input[type="text"].form-control,
input[type="email"].form-control,
input[type="password"].form-control,
input[type="number"].form-control,
textarea.form-control,
textarea.form-control:focus,
select.form-select {
  background-color: var(--primary-color-light);
  border: none;
  color: var(--text-color);
  padding: 10px 14px;
}

input[type="email"].form-control:disabled,
input[type="text"].form-control:disabled {
  cursor: not-allowed;
}

input.form-check-input[type="checkbox"] {
  border: 2px solid var(--primary-color) !important;
}

input.form-check-input[type="checkbox"]:checked {
  background-color: var(--primary-color) !important;
}

.accordion {
  --bs-accordion-bg: var(--sidebar-color);
  --bs-accordion-border-color: var(--primary-color-light);
  --bs-accordion-color: var(--text-color);
  --bs-accordion-border-width: 2px;
  --bs-accordion-btn-padding-y: 0.5rem;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collaped) {
  --bs-accordion-active-bg: var(--sidebar-color);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  --bs-nav-pills-link-active-color: var(--sidebar-color);
  --bs-nav-pills-link-active-bg: var(--primary-color);
}

.nav-link:focus,
.nav-link:hover {
  --bs-nav-link-hover-color: var(--primary-color);
}

.nav-item {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::placeholder {
  opacity: 0.8 !important;
  transition: all 0.3s ease-in-out;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

button {
  transition: all 0.1s ease-in-out;
}

.button-primary {
  /* background-color: var(--primary-color); */
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 6px;
  border: 1px solid var(--primary-color);
  padding: 7px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  transition: var(--tran-05);
}

.button-danger {
  background-color: transparent;
  color: var(--red-accent-color);
  border: 2px solid var(--red-accent-color);
  border-radius: var(--border-radius);
  padding: 7px 10px;
  display: grid;
  place-items: center;
  transition: var(--tran-05);
}

.button-danger i {
  font-size: 1.3rem;
}

.button-danger:hover {
  background-color: var(--red-accent-color);
  color: white;
}

.button-primary:active {
  transform: scale(0.95);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: var(--primary-color);
}

.button-primary i {
  font-size: 1.3rem;
}

.button-primary a {
  color: var(--primary-color);
}

.button-primary:hover a {
  color: white;
}

.button-primary:hover:enabled {
  background-color: var(--primary-color);
  color: white;
}

.spin {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

button:disabled,
button:hover:disabled,
button:active:disabled {
  background-color: transparent !important;
  color: var(--primary-color-disabled) !important;
  border: 1px solid var(--primary-color-disabled) !important;
  cursor: not-allowed;
  transform: none !important;
}

/* override default bootstrap ccs */
.card {
  border: 2px solid var(--primary-color-light);
  background-color: inherit;
}

.card-header {
  border-bottom: none;
  background-color: var(--primary-color-light);
  color: var(--primary-color);
}

span.input-group-text {
  background-color: var(--primary-color-light);
  color: var(--text-color);
  border: none;
}

.nav-tabs {
  --bs-nav-tabs-border-color: transparent;
  --bs-nav-tabs-link-active-bg: var(--sidebar-color);
}

.nav-link.active {
  --bs-nav-tabs-link-active-color: var(--text-color);
}

.nav-link {
  --bs-nav-link-color: var(--primary-color);
  border-color: transparent;
}

/* .nav-tabs .nav-link:focus, */
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link.active {
  border-color: transparent;
  --bs-nav-link-hover-color: var(--primary-color);
}

footer p {
  color: var(--text-color);
  margin-bottom: 0;
}

.hint {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  margin-top: 0.3rem;
  font-size: 0.7rem;
  margin-bottom: 0;
}

.component-header {
  background-color: var(--primary-color-light);
  border-radius: 6px;
  padding: 0.3rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  color: var(--primary-color);
  font-size: 1.1rem;
}

.component-header button {
  border: none;
}

.component-header span strong.count {
  display: inline-block;
  border-radius: 6px;
  padding: 0 0.5rem;
  background-color: var(--primary-color);
  color: var(--sidebar-color);
}

.centered {
  display: grid;
  place-items: center;
}

/* custom styles for the PhoneNumber component */
.PhoneInput {
  background-color: var(--primary-color-light);
  padding: 10px 14px;
  border-radius: var(--border-radius);
  width: 100%;
}

.PhoneInputInput {
  outline: none;
  border: none;
  background-color: inherit;
}
